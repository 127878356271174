import {
  categoryIds,
  CouponTypeValue,
  formatMoney,
  KonakartService,
  T_OrderProduct,
  useCartContainer,
  useCoupon,
  useManagePackage,
  useProductInCart,
  useRunningTotal,
  useTotals,
  useboxesStore,
  productSkuIds,
  Box,
  MyAccountSWR,
  useFreeProduct,
  DartEligibilityCode,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON,
  useIdentifyBox,
  useAddUpdateBox,
  T_Product,
  useHandleRemove,
  useBroadbandSwapProduct,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ,
  couponStoreApi,
  couponCodes,
  T_Coupon,
  NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US,
  BoxTypes,
  CouponType,
  useProductStore,
  useArrowDeliveryStore,
  useOrderStore,
  useCustomerStore,
  bundle_Offer_CouponCode,
  SPLITIO_KEY,
  useSplitIO,
} from '@sky-tv-group/shared';

import React, { useState,useRef } from 'react';
import { RunningTotalProduct } from './RunningTotalProduct';
import { RunningTotalBox } from './RunningTotalBox';
import { RunningTotalOffer } from './RunningTotalOffer';
import { CheckCircle } from './RunningTotalIcons';
import { ArrowDown, ArrowUp } from '../icon';
import { SkyBoxPrimary, SourceJourney } from '../SkyBoxPrimary';
import { ModalConfirmParams } from '../multiroom-modal';
import { Button } from '../button';
interface Props {
  show?: boolean;
  konakartService: KonakartService;
  myAccountSWR: MyAccountSWR;
  showNew?: boolean;
  cartType?: CouponType;
  handleNext?: () => void;
}

const crossout = (boxesFromStore: Box[], box: Box, upgradedTo?: Box) => {
  // Only cross out if is being upgraded to a new box
  // Don't cross out if we are just adding recording to a box
  return boxesFromStore.some(
    b =>
      b.boxType === 'UPGRADE' &&
      b.upgradeFrom?.occurrenceNumber === box.id &&
      b.products
        // filter products existing in the current box
        .filter(bp => !box.products.find(p => p.id === bp.id))
        // if we have another product other than recording, means we are upgrading to a different box
        .some(p => p.categoryId !== categoryIds.recording)
  );
};

interface RunningTotalBoxContainerProps {
  boxesFromStore: Box[];
  box: Box;
  removeBox: (box: Box) => void;
  newUpgradeUi?: boolean;
  showNew?: boolean;
}

const RunningTotalBoxContainer = React.memo(
  ({ box, boxesFromStore, removeBox, newUpgradeUi, showNew }: RunningTotalBoxContainerProps) => {
    let upgradedTo = boxesFromStore.find(
      b => b && box.occurence && b.upgradeFrom?.occurrenceNumber === box.occurence?.occurrenceNumber
    );

    const isArrow = box?.products.some(p => p.sku === productSkuIds.arrowBox.primary);
    const isPod = box?.products.some(p => p.sku === productSkuIds.skyPod.primary);

    const toArrow = !isArrow && upgradedTo?.products.some(p => p.sku === productSkuIds.arrowBox.primary);
    const toPod = !isPod && upgradedTo?.products.some(p => p.sku === productSkuIds.skyPod.primary);

    // if we are upgrading this box to pod or arrow
    // remove upgradeTo products so they don't show as extras like My Sky or multiroom
    if (toPod || toArrow) {
      upgradedTo = undefined;
    }

    if (box.upgradeFrom) {
      // find the box this box is upgrading
      const currentPrimaryBox = boxesFromStore.find(
        b => b.occurence?.occurrenceNumber === box.upgradeFrom?.occurrenceNumber
      );
      const upgradeFromArrow = currentPrimaryBox?.products?.some(p => p.sku === productSkuIds.arrowBox.primary);

      // If this is fusion box upgrade don't render
      // If this an upgrade to an arrow box like adding recording to arrow then don't render
      if ((!isArrow && !isPod) || upgradeFromArrow) return null;
    }

    return (
      <RunningTotalBox
        key={box.id}
        box={box}
        upgradedTo={upgradedTo ?? undefined}
        crossout={crossout(boxesFromStore, box, upgradedTo)}
        // eslint-disable-next-line
        removable={(box.boxType === 'NEW' || box.boxType === 'UPGRADE') && !box.primary}
        removeBox={removeBox}
        newUpgradeUi={newUpgradeUi ?? false}
        showNew={showNew}
      />
    );
  }
);

const RunningTotalInner = ({
  show = true,
  konakartService,
  myAccountSWR,
  showNew,
  cartType = CouponTypeValue.Upgrade,
  handleNext,
}: Props) => {
  const totals = useTotals(myAccountSWR);
  const [showTV, setShowTV] = useState(true);
  const [showDevices, setShowDevices] = useState(true);
  const [showBB, setShowBB] = useState(true);

  const isAcqusition = cartType === CouponTypeValue.Acquisition;
  const yourFirstBillAcq = "Your first bill will be due one month after your Sky activation date and we’ll send this to you 15 days before it’s due. Because we bill one month in advance, your first invoice will include the current month and the following month’s charges.";
  const yourFirstBillBundle ="As we bill one month in advance, your first invoice will include the current month and the following month’s charges. Your first bill will be due one month after your Sky activation date and we’ll send this to you 15 days before it’s due."
  const showRef = useRef<HTMLDivElement | null>(null);
  const {
    currentlySubscribedProducts,
    existingCampaigns,
    hasExistingCampaign,
    currentlySelectedBroadbandPlanForUpdate,
    allActiveCouponsEndDate,
    couponDuration
  } = useManagePackage(myAccountSWR);

  const {
    starter,
    tvPackageProducts,
    addOnProducts,
    broadbandProducts,
    voiceProducts,
    voiceAddonsProducts,
    productsHindiChannels,
    currentlySubscribedFreeProducts,
    currentFreeProductIsASubscribedProduct,
    notCurrentlySubscribedFreeProducts,
    existingOffers: existingOffersnew,
    paperBillingProduct,
    findCampainEndDateForCoupon,
    isOneOffProduct,
    sohoExistsFromPhantomCode,
  } = useRunningTotal(konakartService, existingCampaigns, myAccountSWR);

  let existingOffers = existingOffersnew.filter(
    (obj: any, index: any, self: any) => index === self.findIndex((o: any) => o.name === obj.name)
  );
  const { checkIfExistingProductCanBeMadeFree } = useFreeProduct(konakartService);
  const { changeProductsByQuantity, getProductAndAddons, swapMultipleProducts } = useCartContainer(
    konakartService,
    'UPGRADE'
  );
  const { getBoxFromOccurenceNo } = useboxesStore();
  const { isArrowBox, isSkyPod } = useIdentifyBox();
  const { removeBoxFromStore, addNewBoxtoStore } = useAddUpdateBox();
  const handleRemove = useHandleRemove(konakartService);
  const { meshDevice, removeMesh, meshAdded } = useBroadbandSwapProduct(konakartService);
  const { clearArrowDeliveryStore } = useArrowDeliveryStore();
  const { isOrderPending, clearOrder } = useOrderStore();

  const [isNSBCampaign, NSBconfig] = useSplitIO(SPLITIO_KEY.NSB_POD_CAMPAIGN_CODE_SKIP);
  const nsborPodCampaign = typeof NSBconfig === 'string' && JSON.parse(NSBconfig);

  const { customerId } = useCustomerStore(s => ({
    customerId: s.kk,
  }));
  // Taken out from RunningTotalBox.tsx to avoid multiple re-renders on that component
  const removeBox = async (box: Box) => {
    const upgradeFrom = getBoxFromOccurenceNo(box.upgradeFrom?.occurrenceNumber || '');

    if (isArrowBox(box) && !isArrowBox(upgradeFrom)) {
      changeProductsByQuantity(upgradeFrom?.products ?? [], 1, false);
    }

    if (box.primary && box.boxType === 'NEW') {
      const newMultiroomBoxes = boxesFromStore.filter(b => b.boxType === 'NEW' && !b.primary);
      for await (const mr of newMultiroomBoxes) {
        await changeProductsByQuantity(mr.products ?? [], -1, true);
        removeBoxFromStore(mr.id!);
      }
    }

    changeProductsByQuantity(box.products ?? [], -1, true);
    removeBoxFromStore(box.id!);
  };
  // Taken out from RunningTotalProduct.tsx to avoid multiple re-renders on that component
  const removeProduct = async (product: T_Product) => {
    const isWifiBooster = product.sku === productSkuIds.meshDevice.primary;
    const isWifiRouter = product.sku === productSkuIds.skyRouter.primary;

    if (isWifiRouter) {
      if (meshAdded) removeMesh(meshDevice!);
      await handleRemove(product);
    } else if (isWifiBooster) {
      await removeMesh(meshDevice!);
    } else {
      await handleRemove(product);
    }
  };

  const isSubscribed = (product: T_OrderProduct) => {
    return currentlySubscribedProducts.find(p => p.id === product.productId) !== undefined;
  };
  const showAncillaryCharged = !!paperBillingProduct;

  const { oneOffProductsWithoutBBOneOffFee, broadbandDevices, oneOffProducts, productsInCart } = useProductInCart();
  const nsbOneoffFee = oneOffProducts?.filter(p => p.product.sku == productSkuIds.arrowUpfrontBoxFee.primary);
  const oneOffProductsWithoutBBOneOffFeeAndBoxOneOffFee = oneOffProductsWithoutBBOneOffFee?.filter(
    p =>
      p.product.sku !== productSkuIds.arrowBoxOneOff.primary &&
      p.product.sku !== productSkuIds.skyPodOneOffFee.primary &&
      p.product.sku !== productSkuIds.arrowUpfrontBoxFee.primary
  );
  const boxOneoffFees = oneOffProducts?.filter(p => p.categoryId !== categoryIds.broadbandOneOffFee);
  const boxOneoffFee = boxOneoffFees?.filter(p => p.product.sku !== productSkuIds.arrowBoxOneOff.primary);
  const { getOfferEndsText } = useCoupon();
  const { monthlyFees, coupons } = useCartContainer(konakartService, CouponTypeValue.Upgrade);
  const removeCouponcodes = (coupons: T_Coupon[], code1: any) => {
    return coupons.filter((obj: any) => !code1.includes(obj.couponCode));
  };

  // sky entertainment phantom exists and soho already subscribed hide soho free coupom in offer applied section
  const isSkyEntPhantom =
    productsInCart && productsInCart?.some(product => product.isPhantom === true && product.product.sku === '238');
  const isSohoSubscribed =
    productsInCart &&
    productsInCart?.some(
      product => product.isPhantom === false && product.product.sku === '260' && product.currentlySubscribed === true
    );
  const isSohoOfferShow = isSkyEntPhantom === isSohoSubscribed ? false : true;

  const isUpgradeJourney = productsInCart?.some(p => p.currentlySubscribed === true);
  const isPackageUpgrade = productsInCart?.some(p => p.currentlySubscribed === false);
  const bundleCoupons = couponStoreApi
    .getState()
    .coupons?.find((cp: any) => bundle_Offer_CouponCode.includes(cp.couponCode));

  const newCoupon = removeCouponcodes(coupons, [
    couponCodes.offer50WShohoHindi,
    couponCodes.bundlecoupon_soho_Hindi,
    isUpgradeJourney && bundleCoupons && bundleCoupons.couponCode,
    // couponCodes.bundlecoupon_BB,
  ]);
  const { isSoHoRemovable } = useFreeProduct(konakartService);
  const clearBoxStore = useboxesStore(s => s.clearBoxStore);
  const boxesFromStore = useboxesStore(s => s.boxes);
  const hasBBcoupon = coupons.find(
    coupon =>
      coupon.couponCode === NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON ||
      coupon.couponCode === NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ
  );
  const hasOneMonthOnUsWithSport = coupons.some(c => c.couponCode === couponCodes.oneMonthOnUsWithSport);

  // Primary box and upgrades
  const primaryBoxFromStore = boxesFromStore.find(b => b.boxType !== 'UPGRADE' && b.primary);
  const upgradePrimaryBoxFromStore = boxesFromStore.find(b => b.boxType === 'UPGRADE' && b.primary);

  //new and upgraded boxes
  const newAndUpgradedBoxes = boxesFromStore.filter(b => b.boxType === 'NEW' || b.boxType === 'UPGRADE');

  // return the box type for the one-off fee
  const checkoneoffType = (product: T_OrderProduct) => {
    const focc = newAndUpgradedBoxes.find(n => n?.id === product?.occurrenceNumber);
    let type = '';
    focc?.boxType === 'UPGRADE' ? (type = 'upgrade') : focc?.boxType === 'NEW' ? (type = 'add') : (type = '');
    return type;
  };
  // Multiroom boxes and upgrades including new ones
  const subscribedMultiroomBoxes = boxesFromStore
    .filter(b => b.boxType === 'SUBSCRIBED' && !b.primary) //returns array of multiroom subscribed boxes (not upgrades)
    .reduce((prev: Box[], curr) => {
      //subscribed multiroom boxes
      prev.push(curr);
      // find if there is an upgrade box for this box
      const currUpgrade = boxesFromStore.find(
        b => b.boxType === 'UPGRADE' && b.upgradeFrom?.occurrenceNumber === curr.id
      );
      if (currUpgrade) {
        prev.push(currUpgrade);
      }
      return prev;
    }, []);
  const newPrimaryBox = boxesFromStore.filter(b => b.boxType === BoxTypes.NEW && b.primary);
  const newMultiroomBoxes = boxesFromStore.filter(b => b.boxType === 'NEW' && !b.primary);
  const AllSubscribedBoxes = boxesFromStore.filter(b => b.boxType === 'SUBSCRIBED');
  const upgradedBoxes = boxesFromStore.filter(b => b.boxType === 'UPGRADE');
  const BoxesCounter = boxesFromStore.filter(b => b.boxType === 'SUBSCRIBED' || b.boxType === 'NEW');
  const orderedMultiroomBoxesFromStore = [...subscribedMultiroomBoxes, ...newMultiroomBoxes];
  let primaryBoxes = primaryBoxFromStore
    ? [primaryBoxFromStore, ...(upgradePrimaryBoxFromStore ? [upgradePrimaryBoxFromStore] : [])]
    : [];

  //filtered upgrade boxes
  const filteredUpgradedBoxes = AllSubscribedBoxes.filter(r =>
    upgradedBoxes.some(u => u?.upgradeFrom?.occurrenceNumber === r?.occurence?.occurrenceNumber)
  );

  //To scroll to the ref
  const handleScroll =() => {
    if(showRef.current){
      showRef.current.scrollIntoView({behavior:"smooth",block:"start"})
    }
  }

  //All upgraded boxes for upgrade journey
  let newUpgradeBoxes = filteredUpgradedBoxes.reduce((prev: Box[], curr) => {
    prev.push(curr);
    const currUpgrade = boxesFromStore.find(
      b => b.boxType === 'UPGRADE' && b.upgradeFrom?.occurrenceNumber === curr.id
    );
    if (currUpgrade) {
      prev.push(currUpgrade);
    }
    return prev;
  }, []);

  //get all the existing subscribed box (ignoring upgraded/new)
  const filteredSubscribedBoxes = AllSubscribedBoxes.filter(
    sp => !upgradedBoxes.some(up => sp?.occurence?.occurrenceNumber === up?.upgradeFrom?.occurrenceNumber)
  );

  let AllBoxes = [...primaryBoxes, ...subscribedMultiroomBoxes, ...newMultiroomBoxes];
  let dartEligibilityCodes = [DartEligibilityCode.Discount10[0], DartEligibilityCode.Discount20[0]];
  let dartSohoHindiEligibilityCodes = [DartEligibilityCode.Discount10[1], DartEligibilityCode.Discount20[1]];
  let dartSohoHindiCampaign = existingCampaigns.filter(c => dartSohoHindiEligibilityCodes.includes(c.promotionCode));

  const onBroadbandRemove = () => {
    couponStoreApi.getState().clearCouponFromCode(NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON);
  };

  const has01277 = couponStoreApi
    .getState()
    .coupons.some((c: T_Coupon) => c.couponCode === couponCodes.oneMonthOnUsWithSport);
  const has01292 = couponStoreApi
    .getState()
    .coupons.some((c: T_Coupon) => c.couponCode === couponCodes.offer50WOShohoHindi);

  const has01277ExistingOffer = existingOffers.some(c => c.couponCode === couponCodes.oneMonthOnUsWithSport);
  if (!show) return null;

  const isUpgradeBundleCoupons = isUpgradeJourney
    ? (!isUpgradeJourney && !bundleCoupons) || (isPackageUpgrade && newCoupon.length > 0)
    : true;

  const offerApplied =
    (notCurrentlySubscribedFreeProducts && notCurrentlySubscribedFreeProducts.length > 0) ||
    (coupons && coupons.length > 0) ||
    (productsHindiChannels &&
      productsHindiChannels?.filter(p => !p.inPromotion && !p.currentlySubscribed).length > 1) ||
    (currentFreeProductIsASubscribedProduct &&
      !has01277 &&
      !has01277ExistingOffer &&
      !sohoExistsFromPhantomCode() &&
      isSohoOfferShow);

  const exisingOffersApplied =
    hasExistingCampaign || (currentlySubscribedFreeProducts && currentlySubscribedFreeProducts.length > 0);

  const showOfferEndData = !hasBBcoupon;

  const has04998 = couponStoreApi
    .getState()
    .coupons.some((c: T_Coupon) => c.couponCode === NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US);
  const hasArrowBox = boxesFromStore.some(
    b =>
      b.products.some(p => p.sku === productSkuIds.arrowBox.primary) && (b.boxType === 'NEW' || b.boxType === 'UPGRADE')
  );
  const hasSkyPod = boxesFromStore.some(
    b =>
      b.products.some(p => p.sku === productSkuIds.skyPod.primary) && (b.boxType === 'NEW' || b.boxType === 'UPGRADE')
  );

  //TV counter
  const tvCounter = () => {
    let counter = 1;
    if (tvPackageProducts && tvPackageProducts?.length > 0) {
      counter += tvPackageProducts?.length;
      if (addOnProducts && addOnProducts?.length > 0) {
        counter += addOnProducts?.length;
      }
    }
    return counter + ' items';
  };

  //broadband counter
  const broadbandCounter = () => {
    let counter = 0;
    if (broadbandProducts && broadbandProducts?.length > 0) {
      counter += broadbandProducts?.length;
      if (broadbandDevices && broadbandDevices?.length > 0) {
        counter += broadbandDevices?.length;
      }
      if (voiceAddonsProducts && voiceAddonsProducts?.length > 0) {
        counter += voiceAddonsProducts?.length;
      }
      if (voiceProducts && voiceProducts?.length > 0) {
        counter += voiceProducts?.length;
      }
    }
    return counter + ' items';
  };
  let recordingUpgrade: any[] = [];
  upgradedBoxes.forEach(b => {
    if (b.products.length === 1) {
      let occ = b?.upgradeFrom?.occurrenceNumber;
      let ele = AllBoxes.filter(b => b.id === occ);
      if (ele) {
        recordingUpgrade.push(ele[0]);
        recordingUpgrade.push(b);
      }
    }
  });

  newUpgradeBoxes = newUpgradeBoxes.filter(box => !recordingUpgrade.some(rec => rec.id === box.id));
  let products = useProductStore(s => s.products);
  const clearProducts = useProductStore(s => s.clearProducts);
  let modalProducts = products.filter(p =>
    p?.sku === productSkuIds.arrowBox.primary || p.sku === productSkuIds.skyPod.primary ? p : ''
  );
  const [isError, setError] = useState(false);
  async function confirm(params: ModalConfirmParams) {
    let { selected, oneoff, recording, editing, primary } = params;
    let product = modalProducts.find(p => p.sku === selected)!;
    let products = getProductAndAddons(product, oneoff, recording, primary);

    if(isNSBCampaign){
      const has04998 = couponStoreApi.getState().getCouponFromCode(nsborPodCampaign.nsb_campaign);
      if(!has04998){
        const coupon = await konakartService.getCoupon(nsborPodCampaign.nsb_campaign);
        if (coupon) couponStoreApi.getState().applyCouponToStore(coupon);
      }
    }
    addNewBoxtoStore(product.name, 'NEW', products, undefined, primary);
    if (editing) {
      removeBoxFromStore(editing.id!);
      await swapMultipleProducts(editing.products, products);
    } else {
      await changeProductsByQuantity(products);
      clearArrowDeliveryStore();
    }
  }

  return (
    <div className="flex-col divide-y border-gray-200">
      {/* TV Packages */}
      {starter && !showNew && (
        <div className="pt-4 px-5 mb-6">
          <p className="sky-h7-black sm:sky-h7-black pb-1 mb-3 text-midnight">TV Packages</p>
          {/* Starter. */}
          {starter && (
            <>
              <RunningTotalProduct
                product={starter}
                removable={false}
                subscribed={isSubscribed(starter)}
                removeProduct={removeProduct}
              />
              {tvPackageProducts &&
                tvPackageProducts.length > 0 &&
                tvPackageProducts?.map((product, i) => {
                  let removable =
                    !hasOneMonthOnUsWithSport ||
                    (hasOneMonthOnUsWithSport && product.product.sku !== productSkuIds.entertainment.primary);
                  return (
                    <RunningTotalProduct
                      product={product}
                      key={`${product.productId}_${i}`}
                      subscribed={isSubscribed(product)}
                      removeProduct={removeProduct}
                      removable={removable}
                    />
                  );
                })}
            </>
          )}
        </div>
      )}

      {/* TV Packages for upgrade journey (new) */}
      {starter && showNew && (
        <div className="pt-4 px-5 mb-6">
          <div className="flex w-full justify-between">
            <div className="flex flex-row">
              <p className="sky-h4-black pb-1 mb-3 text-midnight">
                TV
                <span className="inline-block  sky-h7-reg ml-2" style={{ color: '#686868' }}>
                  {tvCounter()}
                </span>{' '}
              </p>
            </div>
            <div>
              <span onClick={() => setShowTV(!showTV)}>{showTV ? <ArrowUp /> : <ArrowDown />}</span>
            </div>
          </div>

          {/* Starter. */}
          {starter && showTV && (
            <>
              <RunningTotalProduct
                product={starter}
                removable={false}
                subscribed={isSubscribed(starter)}
                removeProduct={removeProduct}
                showNew={showNew}
              />
              {tvPackageProducts &&
                tvPackageProducts.length > 0 &&
                tvPackageProducts?.map((product, i) => {
                  let removable =
                    (!hasOneMonthOnUsWithSport ||
                      (hasOneMonthOnUsWithSport && product.product.sku !== productSkuIds.entertainment.primary)) &&
                    !sessionStorage.getItem('isBundle');
                  return (
                    <RunningTotalProduct
                      product={product}
                      key={`${product.productId}_${i}`}
                      subscribed={isSubscribed(product)}
                      removeProduct={removeProduct}
                      removable={removable}
                      showNew={showNew}
                    />
                  );
                })}
              {/* Add ons */}
              {addOnProducts &&
                addOnProducts.length > 0 &&
                showNew === true &&
                addOnProducts?.map((product, i) => {
                  return (
                    <RunningTotalProduct
                      product={product}
                      key={`${product.productId}_${i}`}
                      subscribed={isSubscribed(product)}
                      removable={isSoHoRemovable(notCurrentlySubscribedFreeProducts, product)}
                      removeProduct={removeProduct}
                      showNew={showNew}
                    />
                  );
                })}
            </>
          )}
        </div>
      )}

      {/* Add ons */}
      {addOnProducts && addOnProducts.length > 0 && !showNew && (
        <div className="pt-4 px-5 mb-6">
          <p className="sky-h7-black sm:sky-h7-black pb-1 mb-3 text-midnight">Premium Channels</p>
          {addOnProducts?.map((product, i) => (
            <RunningTotalProduct
              product={product}
              key={`${product.productId}_${i}`}
              subscribed={isSubscribed(product)}
              removable={isSoHoRemovable(notCurrentlySubscribedFreeProducts, product)}
              removeProduct={removeProduct}
            />
          ))}
        </div>
      )}

      {/* Sky Box */}

      {starter && !showNew && (
        <>
          {primaryBoxes.length ? (
            <div className="pt-4 px-5 mb-6">
              <p className="sky-h7-black sm:sky-h7-black pb-1 mb-3 text-midnight">Sky Box</p>
              {primaryBoxes.map(box => (
                <RunningTotalBoxContainer key={box.id} box={box} boxesFromStore={primaryBoxes} removeBox={removeBox} />
              ))}
            </div>
          ) : null}
          {orderedMultiroomBoxesFromStore.length ? (
            <div className="pt-4 px-5 mb-6">
              <p className="sky-h7-black sm:sky-h7-black pb-1 mb-3 text-midnight">Multiroom Box</p>
              {orderedMultiroomBoxesFromStore.map(box => (
                <RunningTotalBoxContainer
                  key={box.id}
                  box={box}
                  boxesFromStore={orderedMultiroomBoxesFromStore}
                  removeBox={removeBox}
                />
              ))}
            </div>
          ) : null}
        </>
      )}

      {/* Sky Box new UI for upgrade journey */}
      {starter && showNew && (
        <div className="pt-4 px-4 mb-6">
          {((BoxesCounter?.length !== 0 && !isAcqusition) || isAcqusition) && (
            <div className="flex w-full justify-between">
              <div className="flex flex-row">
                <p className="sky-h4-black pb-1 mb-3 text-midnight">
                  TV Devices
                  <span className="inline-block  sky-h7-reg ml-2" style={{ color: '#686868' }}>
                    {showNew && BoxesCounter ? BoxesCounter.length + ' items' : ''}
                  </span>
                </p>{' '}
              </div>
              <div>
                {' '}
                <span onClick={() => setShowDevices(!showDevices)}>{showDevices ? <ArrowUp /> : <ArrowDown />}</span>
              </div>
            </div>
          )}
          {showDevices && (
            <>
              {AllBoxes.length === 0 && sessionStorage.getItem('isBundle') ? (
                <div ref={showRef}>
                  <SkyBoxPrimary
                    heading="Choose your TV Device:"
                    box={primaryBoxes[0]}
                    source={SourceJourney.WEB_ICE}
                    products={modalProducts}
                    confirm={confirm}
                    changeProductsByQuantity={changeProductsByQuantity}
                    error={isError}
                  />
                  <p className="my-6">
                    If your home lacks an internet connection or your service coverage is poor, please contact our
                    friendly Sky crew on{' '}
                    <a href="tel:09 525 5555 " className="text-blue-pure underline text-sm">
                      09 525 5555{' '}
                    </a>{' '}
                    for alternative solutions to enjoy Sky.
                  </p>
                </div>
              ) : null}
              {!AllBoxes?.length && showDevices && isAcqusition ? (
                !sessionStorage.getItem('isBundle') ? (
                  <span className="sky-h6-reg text-red">To enjoy Sky TV you must choose a TV Device.</span>
                ) : (
                  ''
                )
              ) : (
                <>
                  {sessionStorage.getItem('isBundle') && newPrimaryBox.length > 0 ? (
                    <>
                      <SkyBoxPrimary
                        heading="Choose your TV Device:"
                        box={primaryBoxes[0]}
                        source={SourceJourney.WEB_ICE}
                        products={modalProducts}
                        confirm={confirm}
                        changeProductsByQuantity={changeProductsByQuantity}
                      />
                      <p className="my-6">
                        If your home lacks an internet connection or your service coverage is poor, please contact our
                        friendly Sky crew on{' '}
                        <a href="tel:09 525 5555 " className="text-blue-pure underline text-sm">
                          09 525 5555{' '}
                        </a>{' '}
                        for alternative solutions to enjoy Sky.
                      </p>
                    </>
                  ) : (
                    ''
                  )}
                  {newPrimaryBox?.length && showDevices && !sessionStorage.getItem('isBundle') ? (
                    <div className="pt-4 px-4 mb-6">
                      {newPrimaryBox.map(box => (
                        <RunningTotalBoxContainer
                          key={box.id}
                          box={box}
                          boxesFromStore={newPrimaryBox}
                          removeBox={removeBox}
                          newUpgradeUi={true}
                          showNew={showNew}
                        />
                      ))}
                    </div>
                  ) : null}

                  {filteredSubscribedBoxes.length && showDevices ? (
                    <div className="pt-4 px-4 mb-6">
                      {filteredSubscribedBoxes.map(box => (
                        <RunningTotalBoxContainer
                          key={box.id}
                          box={box}
                          boxesFromStore={filteredSubscribedBoxes}
                          removeBox={removeBox}
                          newUpgradeUi={true}
                          showNew={showNew}
                        />
                      ))}
                    </div>
                  ) : null}

                  {recordingUpgrade.map(box => (
                    <div className="px-4">
                      <RunningTotalBoxContainer
                        key={box.id}
                        box={box}
                        boxesFromStore={recordingUpgrade}
                        removeBox={removeBox}
                        newUpgradeUi={true}
                        showNew={showNew}
                      />
                    </div>
                  ))}

                  {newUpgradeBoxes.length > 0 && showDevices ? (
                    <div className="mt-4">
                      <div className="flex sky-h6-black w-full h-10 sky-bg-gray-light items-center mb-2">
                        <span className="p-2 ">Upgrades</span>
                      </div>
                      {newUpgradeBoxes.map(box => (
                        <div className="px-4">
                          <RunningTotalBoxContainer
                            key={box.id}
                            box={box}
                            boxesFromStore={newUpgradeBoxes}
                            removeBox={removeBox}
                            newUpgradeUi={true}
                            showNew={showNew}
                          />
                        </div>
                      ))}
                    </div>
                  ) : null}

                  {newMultiroomBoxes.length && showDevices ? (
                    <div className="">
                      {!isAcqusition && (
                        <div className="flex sky-h6-black w-full h-10 sky-bg-gray-light items-center">
                          <span className="p-2">Additions</span>
                        </div>
                      )}
                      <div className="mt-4">
                        {newMultiroomBoxes.map(box => (
                          <RunningTotalBoxContainer
                            key={box.id}
                            box={box}
                            boxesFromStore={newMultiroomBoxes}
                            removeBox={removeBox}
                            showNew={showNew}
                          />
                        ))}
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
      )}

      {/* Broadband  ui for upgrade journey */}
      {broadbandProducts && broadbandProducts.length > 0 && showNew && (
        <div className="pt-4 px-5 mb-6">
          {showNew ? (
            <div className="flex w-full justify-between">
              <div className="flex flex-row">
                <p className="sky-h4-black pb-1 mb-3 text-midnight">
                  Broadband
                  <span className="inline-block  sky-h7-reg ml-2" style={{ color: '#686868' }}>
                    {showNew ? broadbandCounter() : ''}
                  </span>{' '}
                </p>
              </div>
              <div>
                <span onClick={() => setShowBB(!showBB)}>{showBB ? <ArrowUp /> : <ArrowDown />}</span>
              </div>
            </div>
          ) : (
            <p className="sky-h7-black sm:sky-h7-black pb-1 mb-3 text-midnight">
              Broadband
              <span className="text-xs inline-block  sky-h7-reg ml-2" style={{ color: '#686868' }}>
                {showNew ? broadbandCounter() : ''}
              </span>{' '}
            </p>
          )}
          {showBB &&
            broadbandProducts?.map((product, i) => {
              return (
                <RunningTotalProduct
                  product={product}
                  key={`${product.productId}_${i}`}
                  subscribed={isSubscribed(product)}
                  crossout={
                    product.currentlySubscribed &&
                    currentlySelectedBroadbandPlanForUpdate &&
                    currentlySelectedBroadbandPlanForUpdate.sku !== product.product.sku
                  }
                  removeProduct={removeProduct}
                  removeHandlerEnd={onBroadbandRemove}
                  showNew={showNew}
                />
              );
            })}
          {/* Devices. */}
          {showBB &&
            broadbandDevices &&
            broadbandDevices.length > 0 &&
            broadbandDevices?.map((product: T_OrderProduct, i) => (
              <RunningTotalProduct
                product={product}
                key={`${product.productId}_${i}`}
                subscribed={isSubscribed(product)}
                removeProduct={() => {}}
                removable={false}
                showNew={showNew}
              />
            ))}
          {/* Voice. */}
          {showBB &&
            voiceProducts &&
            voiceProducts.length > 0 &&
            voiceProducts?.map((product: T_OrderProduct, i) => (
              <RunningTotalProduct
                product={product}
                key={`${product.productId}_${i}`}
                subscribed={isSubscribed(product)}
                removeProduct={removeProduct}
                showNew={showNew}
              />
            ))}

          {/* Voice Addons. */}
          {showBB &&
            voiceAddonsProducts &&
            voiceAddonsProducts.length > 0 &&
            voiceAddonsProducts?.map((product: T_OrderProduct, i) => (
              <RunningTotalProduct
                product={product}
                key={`${product.productId}_${i}`}
                subscribed={isSubscribed(product)}
                removeProduct={removeProduct}
                showNew={showNew}
              />
            ))}
        </div>
      )}

      {/* Broadband  */}
      {broadbandProducts && broadbandProducts.length > 0 && !showNew && (
        <div className="pt-4 px-5 mb-6">
          <p className="sky-h7-black sm:sky-h7-black pb-1 mb-3 text-midnight">Broadband</p>
          {broadbandProducts?.map((product, i) => {
            return (
              <RunningTotalProduct
                product={product}
                key={`${product.productId}_${i}`}
                subscribed={isSubscribed(product)}
                crossout={
                  product.currentlySubscribed &&
                  currentlySelectedBroadbandPlanForUpdate &&
                  currentlySelectedBroadbandPlanForUpdate.sku !== product.product.sku
                }
                removeProduct={removeProduct}
                removeHandlerEnd={onBroadbandRemove}
              />
            );
          })}
          {/* Devices. */}
          {broadbandDevices &&
            broadbandDevices.length > 0 &&
            broadbandDevices?.map((product: T_OrderProduct, i) => (
              <RunningTotalProduct
                product={product}
                key={`${product.productId}_${i}`}
                subscribed={isSubscribed(product)}
                removeProduct={() => {}}
                removable={false}
              />
            ))}
          {/* Voice. */}
          {voiceProducts &&
            voiceProducts.length > 0 &&
            voiceProducts?.map((product: T_OrderProduct, i) => (
              <RunningTotalProduct
                product={product}
                key={`${product.productId}_${i}`}
                subscribed={isSubscribed(product)}
                removeProduct={removeProduct}
              />
            ))}

          {/* Voice Addons. */}
          {voiceAddonsProducts &&
            voiceAddonsProducts.length > 0 &&
            voiceAddonsProducts?.map((product: T_OrderProduct, i) => (
              <RunningTotalProduct
                product={product}
                key={`${product.productId}_${i}`}
                subscribed={isSubscribed(product)}
                removeProduct={removeProduct}
              />
            ))}
        </div>
      )}

      {/* One off products ui for upgrade journey */}
      {boxOneoffFee && boxOneoffFee.length > 0 && showNew && (
        <div className="pt-4 px-5 mb-6">
          <p className="sky-h4-black pb-1 mb-3 text-midnight">One-off Charges</p>
          {boxOneoffFee.map((oneOffProduct, i) => (
            <RunningTotalProduct
              product={oneOffProduct}
              key={`${oneOffProduct.productId}_${i}`}
              subscribed={isSubscribed(oneOffProduct)}
              oneoffBoxType={checkoneoffType(oneOffProduct)}
              removable={
                oneOffProduct.categoryId !== categoryIds.broadbandOneOffFee &&
                oneOffProduct.product.sku !== productSkuIds.arrowUpfrontBoxFee.primary &&
                oneOffProduct.product.sku !== productSkuIds.skyPodOneOffFee.primary
              }
              oneOff={true}
              removeProduct={removeProduct}
              showoneOff={true}
              showNew={showNew}
            />
          ))}
        </div>
      )}

      {/* Ancillary Charges */}
      {showAncillaryCharged && (
        <div className="pt-4 px-5 mb-6">
          <p className={`${showNew ? 'sky-h4-black' : 'sky-h7-black'} pb-1 mb-3 text-midnight`}>Ancillary Charges</p>
          {/* Paper Billing */}
          {paperBillingProduct && (
            <RunningTotalProduct
              product={paperBillingProduct}
              key={paperBillingProduct.productId}
              subscribed={false}
              removable={false}
              removeProduct={removeProduct}
              showNew={showNew}
            />
          )}
        </div>
      )}

      {/* Existing Offers */}
      {(hasExistingCampaign && existingOffers?.length >0 ||
        (currentlySubscribedFreeProducts &&
          currentlySubscribedFreeProducts.length > 0 &&
          !currentFreeProductIsASubscribedProduct) ||
        (productsHindiChannels && productsHindiChannels?.filter(p => p.currentlySubscribed).length > 1)) && (
        <div className="pt-4 px-5 mb-6">
          <p className={`${showNew ? 'sky-h4-black' : 'sky-h7-black'} pb-1 mb-3`} style={{ color: '#9100D4' }}>
            Existing Offers
          </p>
          {hasExistingCampaign &&
            //Display matching coupon if found
            (existingOffers && existingOffers !== undefined
              ? existingOffers
                  ?.filter(coupon => coupon.couponCode !== '09055BB')
                  ?.map((offer, i) => {
                    let campaign = existingCampaigns.find(c => c.promotionCode === offer.couponCode);
                    let servicesCode = [
                      ...(campaign?.containedServices.CableServiceOccurrence ?? []),
                      ...(campaign?.containedServices.DataServiceOccurrence ?? []),
                      ...(campaign?.containedServices.OTTServiceOccurrence ?? []),
                      ...(campaign?.containedServices.TelephoneServiceOccurrence ?? []),
                    ]
                      .flatMap(s => s.serviceDetails)
                      .map(s => s.serviceCode);

                    let appliedProducts = monthlyFees.filter(
                      op => servicesCode.includes(op.product.sku) && op.inPromotion
                    );

                    // Has Soho or Hindi+1 dart rate code
                    if (dartEligibilityCodes.includes(offer.couponCode) && dartSohoHindiCampaign.length) {
                      const dartSohoHindiCampaignSvc = dartSohoHindiCampaign
                        .flatMap(c => c.containedServices?.CableServiceOccurrence)
                        .flatMap(s => s.serviceDetails)
                        .map(s => s.serviceCode);

                      appliedProducts = [
                        ...appliedProducts,
                        ...monthlyFees.filter(
                          op => dartSohoHindiCampaignSvc.includes(op.product.sku) && op.inPromotion
                        ),
                      ];
                    }

                    return (
                      <RunningTotalOffer
                        key={`${offer?.id}_${i}`}
                        coupon={offer}
                        offer={undefined}
                        offerText={getOfferEndsText(
                          offer!,
                          appliedProducts,
                          null,
                          true,
                          true,
                          findCampainEndDateForCoupon(offer.couponCode)
                        )}
                        showNew={showNew}
                      />
                    );
                  })
              : // Display campaign code if no matching coupon is found
                existingCampaigns.map((campaign, i) => {
                  return (
                    <RunningTotalOffer
                      key={`${campaign.promotionCode}_${i}`}
                      campaign={campaign}
                      coupon={undefined}
                      offer={undefined}
                      offerText={''}
                      showNew={showNew}
                    />
                  );
                }))}
          {!has01277ExistingOffer &&
            currentlySubscribedFreeProducts &&
            currentlySubscribedFreeProducts.length > 0 &&
            currentlySubscribedFreeProducts?.map(
              (product, i) =>
                !checkIfExistingProductCanBeMadeFree(product.productId) && (
                  <RunningTotalOffer
                    coupon={undefined}
                    offer={product}
                    offerText={''}
                    key={`${product.productId}_${i}`}
                    showNew={showNew}
                  />
                )
            )}
          {productsHindiChannels && productsHindiChannels?.filter(p => p.currentlySubscribed).length > 1 && (
            <>
              <p
                className={`${
                  showNew ? 'sky-h6-reg' : 'sky-h7-reg'
                } text-midnight pb-2 items-center justify-between max-w-80%`}
                data-testid={`running-total-offer-hindi-channels`}>
                <span className="flex items-center">
                  <span
                    className="icon-wrapper mr-3"
                    style={{
                      width: '15px',
                      height: '15px',
                      display: 'inline-block',
                      cursor: 'pointer',
                      color: '#9100D4',
                    }}>
                    <CheckCircle />
                  </span>
                  {'Hindi Channels Bundle'}
                </span>
                <span className={`${showNew ? 'sky-h7-reg' : 'sky-sub'} inline-block pl-7`}>
                  {'Bundle any two Hindi language channels for $14.99/mth or all three for $19.99/mth'}
                </span>
              </p>
            </>
          )}
        </div>
      )}
      {/* Offers */}
      {offerApplied && isUpgradeBundleCoupons && (
        <div className="pt-4 px-5 mb-6">
          <p className={`${showNew ? 'sky-h4-black' : 'sky-h7-black'} pb-1 mb-3`} style={{ color: '#9100D4' }}>
            Offer Applied
          </p>
          {newCoupon?.map((product, i) =>
            product.couponCode !== NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US ? (
              <RunningTotalOffer
                key={`${product.id}_${i}`}
                coupon={product}
                offer={undefined}
                offerText={
                  !isOneOffProduct(product) ? getOfferEndsText(product, monthlyFees, null, true) : product.description
                }
                showNew={showNew}
              />
            ) : (
              ''
            )
          )}
          {!has01277 &&
            notCurrentlySubscribedFreeProducts &&
            notCurrentlySubscribedFreeProducts.length > 0 &&
            notCurrentlySubscribedFreeProducts?.map((product, i) => (
              <RunningTotalOffer
                coupon={undefined}
                offer={product}
                offerText={''}
                key={`${product.productId}_${i}`}
                showNew={showNew}
              />
            ))}
          {/* added additonal render for Soho Specific Offer as it comes under already subscribed offer */}
          {!has01277 &&
            !has01277ExistingOffer &&
            isSohoOfferShow &&
            currentlySubscribedFreeProducts &&
            currentlySubscribedFreeProducts.length > 0 &&
            currentlySubscribedFreeProducts?.map(
              (product, i) =>
                checkIfExistingProductCanBeMadeFree(product.productId) && (
                  <RunningTotalOffer
                    coupon={undefined}
                    offer={product}
                    offerText={''}
                    key={`${product.productId}_${i}`}
                    showNew={showNew}
                  />
                )
            )}
          {productsHindiChannels &&
            productsHindiChannels?.filter(p => !p.inPromotion && !p.currentlySubscribed).length > 1 && (
              <>
                <p
                  className={`${
                    showNew ? 'sky-h6-reg' : 'sky-h7-reg'
                  } text-midnight pb-2 items-center justify-between max-w-80%`}
                  data-testid={`running-total-offer-hindi-channels`}>
                  <span className="flex items-center">
                    <span
                      className="icon-wrapper mr-3"
                      style={{
                        width: '15px',
                        height: '15px',
                        minWidth: '15px',
                        display: 'inline-block',
                        cursor: 'pointer',
                        color: '#9100D4',
                      }}>
                      <CheckCircle />
                    </span>
                    {'Hindi Channels Bundle'}
                  </span>
                  <span className={`${showNew ? 'sky-h7-reg' : 'sky-sub'} inline-block pl-7`}>
                    {'Bundle any two Hindi language channels for $14.99/mth or all three for $19.99/mth'}
                  </span>
                </p>
              </>
            )}
          {has04998 && hasArrowBox && (
            <>
              <p
                className={`${
                  showNew ? 'sky-h6-reg' : 'sky-h7-reg'
                } text-midnight pb-2 items-center justify-between max-w-80%`}
                data-testid={`running-total-offer-nsb-oneoff`}>
                <span className="flex items-center">
                  <span
                    className="icon-wrapper mr-3"
                    style={{
                      width: '15px',
                      minWidth: '15px',
                      height: '15px',
                      display: 'inline-block',
                      cursor: 'pointer',
                      color: '#9100D4',
                    }}>
                    <CheckCircle />
                  </span>
                  {`New Sky Box on us`}
                </span>
                <span
                  className={`${
                    showNew ? 'sky-h7-reg' : 'sky-sub'
                  } inline-block pl-7`}>{`New Sky Box on us (usually $ 200)`}</span>
              </p>
            </>
          )}
          {has04998 && hasSkyPod && (
            <>
              <p
                className={`${
                  showNew ? 'sky-h6-reg' : 'sky-h7-reg'
                } text-midnight pb-2 items-center justify-between max-w-80%`}
                data-testid={`running-total-offer-nsb-oneoff`}>
                <span className="flex items-center">
                  <span
                    className="icon-wrapper mr-3"
                    style={{
                      width: '15px',
                      minWidth: '15px',
                      height: '15px',
                      display: 'inline-block',
                      cursor: 'pointer',
                      color: '#9100D4',
                    }}>
                    <CheckCircle />
                  </span>
                  {`Sky Pod on us`}
                </span>
                <span
                  className={`${
                    showNew ? 'sky-h7-reg' : 'sky-sub'
                  } inline-block pl-7`}>{`Sky Pod on us (usually $ 100)`}</span>
              </p>
            </>
          )}
        </div>
      )}

      {/* One off products. */}
      {oneOffProductsWithoutBBOneOffFeeAndBoxOneOffFee &&
        oneOffProductsWithoutBBOneOffFeeAndBoxOneOffFee.length > 0 &&
        !showNew && (
          <div className="pt-4 px-5 mb-6">
            <p className="sky-h7-black sm:sky-h7-black pb-1 mb-3 text-midnight">One-off Charges</p>
            {oneOffProductsWithoutBBOneOffFeeAndBoxOneOffFee.map((oneOffProduct, i) => (
              <RunningTotalProduct
                product={oneOffProduct}
                key={`${oneOffProduct.productId}_${i}`}
                subscribed={isSubscribed(oneOffProduct)}
                removable={
                  oneOffProduct.categoryId !== categoryIds.broadbandOneOffFee &&
                  oneOffProduct.product.sku !== productSkuIds.arrowUpfrontBoxFee.primary &&
                  oneOffProduct.product.sku !== productSkuIds.skyPodOneOffFee.primary
                }
                oneOff={true}
                removeProduct={removeProduct}
              />
            ))}
          </div>
        )}

      {showNew ? (
        // New UI
        <div className="flex flex-col w-full items-end">
          <div className="flex w-full md:w-4/5 flex-col gap-6 p-5 border-b border-gray-200 text-navy">
            <p className="sky-h4-black pb-2 flex items-start justify-between">
              <span className="flex flex-col gap-2 md:flex-row md:items-center">
                {isAcqusition ? (
                  <>
                    {sessionStorage.getItem('isBundle')
                    ?
                    <>
                        <div>
                          <div className="lg:flex md:flex block">
                              <div> Monthly charge</div>
                              <div className='sky-h7-reg items-center lg:ml-2 md:ml-2 lg:mt-1 md:mt-1' style={{ color: '#9100D4' }}>
                                offer applied
                                <span
                                  className="icon-wrapper ml-3"
                                  style={{
                                    width: '15px',
                                    height: '13px',
                                    display: 'inline-block',
                                    cursor: 'pointer',
                                    color: '#9100D4',
                                  }}>
                                  <CheckCircle />
                                </span>
                              </div>
                            </div>
                          {offerApplied &&
                            <div>
                              <div className='mt-4' style={{fontSize:"12px"}}>
                                {formatMoney(isAcqusition &&
                                  totals?.monthlyPriceWithoutOffer)}/mth
                                <span className="ml-2 font-size-12px">
                                  {showOfferEndData ? `after offer expires ${couponDuration} months from your Sky activation date` : `After offer expires`}
                                </span>
                              </div>
                            </div>
                          }
                        </div>
                    </>
                    :
                    <>
                      Monthly charge
                      {offerApplied && <span className="sky-h7-reg">With offer applied</span>}
                    </>
                    }
                  </>
                ) : (
                  <>
                    <span className="sky-h4-reg">My Current Package Price</span>
                    {exisingOffersApplied && <span className="sky-h7-reg">with any existing offers</span>}
                  </>
                )}
              </span>
              <span className={`${!isAcqusition ? 'sky-h4-reg' : ''}`}>
                {formatMoney(isAcqusition ? totals?.monthlyPriceWithOffer : totals?.monthlyCharge)}/mth
              </span>
            </p>
            {allActiveCouponsEndDate && !sessionStorage.getItem('isBundle') && (
              <p className="sky-h4-black pb-2 flex items-start justify-between">
                <span className="flex flex-col gap-2 md:flex-row md:items-center">
                  {isAcqusition ? (
                    <>
                      Monthly charge
                      <span className="sky-h7-reg">
                        {showOfferEndData ? `After offer expires ${couponDuration} months from your Sky activation date` : `After offer expires`}
                      </span>
                    </>
                  ) : (
                    <>
                      My New Package Price
                      {(exisingOffersApplied || offerApplied) && (
                        <span className="sky-h7-reg">with any existing and new offers</span>
                      )}
                    </>
                  )}
                </span>
                <span>
                  {formatMoney(isAcqusition ? totals?.monthlyPriceWithoutOffer : totals?.monthlyPriceWithOffer)}/mth
                </span>
              </p>
            )}
          </div>
          <div className="flex flex-col w-full md:w-4/5 p-5 gap-6 mb-6 text-navy">
            {/* we don’t currently want to call out a shipping cost even if it is free, but in future this might change from business requirements. */}
            {/* <p className="sky-h4-black flex items-start justify-between">
              <span>
                Shipping
                <br />
                <span className="sky-h7-reg">Estimated arrival 3-5 working days.</span>
              </span>
              <span>FREE</span>
            </p> */}
            <p className={`${sessionStorage.getItem('isBundle') ? 'sky-h7-black space-x-8':'sky-h4-black'} flex items-start justify-between`}>
              <span>
                {isAcqusition ? sessionStorage.getItem('isBundle') ? 'Your first bill estimate (for your first two months of your Sky subscription)': 'Your First Bill Estimate' : 'Your Next Bill'} <br />
                {(exisingOffersApplied || offerApplied) && (
                  <span className={`${sessionStorage.getItem('isBundle')?'font-size-12px':'sky-h7-reg'}`}>
                  {isAcqusition ? sessionStorage.getItem('isBundle')?
                    yourFirstBillBundle
                  :
                    yourFirstBillAcq
                  : "Includes any one-off charges that may apply to your new package."}
                  </span>
                )}
              </span>
              <span>
                {isAcqusition
                  ? sessionStorage.getItem('isBundle') ? formatMoney((totals.monthlyPriceWithOffer*2) ):formatMoney(totals.priceYouPayToday + totals.monthlyPriceWithOffer)
                  : formatMoney(totals.priceYouPayToday)}
              </span>
            </p>
          </div>
        </div>
      ) : (
        <div className="pt-4 px-5 mb-6 text-midnight lg:hidden">
          <p className="sky-h7-reg pb-2 flex items-center justify-between">
            <span>
              Monthly charge
              <br />
              {offerApplied && <span className="sky-sub">With offer applied</span>}
            </span>
            <span>{formatMoney(totals.monthlyPriceWithOffer)}/mth</span>
          </p>
          {allActiveCouponsEndDate && (
            <p className="sky-h7-reg pb-2 flex items-center justify-between">
              <span>
                Monthly charge
                <br />
                <span className="sky-sub">
                  {showOfferEndData ? `From ${allActiveCouponsEndDate}` : `After offer period`}
                </span>
              </span>
              <span>{formatMoney(totals.monthlyPriceWithoutOffer)}/mth</span>
            </p>
          )}
        </div>
      )}
      {location.pathname === '/build-my-plan/checkout' && sessionStorage.getItem('isBundle') === 'true' && (
        <div id="createUrOwnPackageConatainer"
        className="flex flex-col md:flex-row justify-between w-11/12 mx-auto my-2 p-4 space-y-4 md:space-y-0 md:space-x-4">
        {/* className="flex flex-no-wrap justify-between  w-11/12  mx-auto my-2 p-4"> */}
          <Button
            className="min-w-64"
            variant="secondary"
            disabled={false}
            colorMode="pure"
            id="createUrOwnBundle"
            onClick={async () => {
              couponStoreApi.getState().removeAllCoupons();
              clearProducts(konakartService);
              await clearOrder(konakartService, customerId);
              clearBoxStore();
              sessionStorage.removeItem('isBundle');
              location.replace('/');
            }}>
            Build My Own Package
          </Button>
          <Button
            id="createUrOwnBundleNextBtn"
            data-testid="next-to-checkout-button"
            colorMode={
              (isOrderPending || boxesFromStore?.length <= 0) && !Boolean(sessionStorage.getItem('isBundle'))
                ? 'disabled'
                : 'pure'
            }
            variant="primary"
            onClick={() => {
              if (sessionStorage.getItem('isBundle') && boxesFromStore?.length <= 0) {
                handleScroll();
                setError(true);
                return;
              } else {
                if (handleNext) {
                  handleNext();
                }
              }
            }}
            disabled={(isOrderPending || boxesFromStore?.length <= 0) && !Boolean(sessionStorage.getItem('isBundle'))}
            className="min-w-64">
            Next: Your Details
          </Button>
        </div>
      )}
    </div>
  );
};

export { RunningTotalInner };
