import { JSONConfigService, productSkuIds, T_PlanSelector, useBBname, segment } from '@sky-tv-group/shared';
import AnimatedModal from '../AnimatedModal';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { IIcedPackageCardProps } from '../package-card/IcedPackageCard';
import { BBMarketingInfo } from './BBMarketingInfo';
import { ExploreBanner } from './ExploreBanner';
import { LatestTech } from './latestTech';
import { useHash } from './useModal';

export interface IExploreModalProps extends IIcedPackageCardProps {
  isShown: boolean;
  plan: T_PlanSelector;
  hide: () => void;
  isStarterInCart: boolean;
  content: T_SpeedShowCase;
  configService: JSONConfigService;
  selectedTuiAddress?: string;
  parentPrice?: any;
}
let fluidFontSize = 'calc(20px + (30 - 20) * ((100vw - 320px) / (1024-320)))';

export interface T_SpeedShowCase {
  background: string;
  products: {
    name: string;
    backgroundImg: string;
    mobileBackgroundImg: string;
    download: {
      title: string;
      desc: string;
      speed: string;
    };
    upload: {
      title: string;
      desc: string;
      speed: string;
    };
  }[];
}

const BBExploreModal: FunctionComponent<IExploreModalProps> = ({
  isShown,
  plan,
  hide,
  toggleProduct,
  kkService,
  isStarterInCart,
  content,
  configService,
  selectedTuiAddress,
  parentPrice,
}) => {
  useHash(hide, isShown);
  const bbName = useBBname();
  const [sendAnalytics, setStateForExploreAnalytics] = useState(true);
  let price = parentPrice;
  const isFastWifi =
    plan?.product?.sku === productSkuIds.broadbandLightningFastWiFi.primary ||
    plan?.product?.sku === productSkuIds.broadbandLightningFastWiFiBoost.primary ||
    plan?.product?.sku === productSkuIds.broadbandStarterDiscountOneGig.primary;

  const isFibreEveryday =
    plan?.product?.sku === productSkuIds.broadbandWifi100.primary ||
    plan?.product?.sku === productSkuIds.broadbandWifi100Boost.primary ||
    plan?.product?.sku === productSkuIds.broadbandStarterDiscountHundredMeg.primary;

  const contentIndex = isFastWifi ? 0 : isFibreEveryday ? 1 : 2;

  if (!isShown) {
      return null;
  }
  if(isShown){
    let priceObj = {
      product: plan?.product,
      price: price,
      explore: true
    }
    window.localStorage.setItem("ExploreObj",JSON.stringify(priceObj));
  }else{
    window.localStorage.setItem("ExploreObj",'');
  }

  const caption = () => {

    switch (plan.product!.sku) {
      case productSkuIds.broadbandWifi100Boost.primary:
        return (
          <>
            <p className="py-4 md:sky-h5 sky-h5 text-gray-darker">
              If breakneck speed isn’t your priority then check out Sky Broadband’s WiFi 100 plans for unlimited fibre
              and the latest WiFi 6 tech.
            </p>
            <p className="pt-4 md:sky-h5 sky-h5 text-gray-darker">
              Need your WiFi to go further? Our Boost plans include the use of a Sky WiFi Booster. We suggest getting a
              Boost plan if your home is:
            </p>
            <ul className="pb-4 md:sky-h5 sky-h5 text-gray-darker marker:text-gray list-outside list-disc ml-6">
              <li>Over 200m2</li>
              <li>Has two or more levels</li>
              <li>Has two or more lounge rooms</li>
              <li>Has four or more bedrooms</li>
              <li>Has any internal walls made from concrete or steel</li>
            </ul>
            <p className="py-4 md:sky-h5 sky-h5 text-gray-darker">
              One booster should do the trick, but if you find you need more coverage give our friendly Kiwi crew a
              call.
            </p>
          </>
        );
      case productSkuIds.broadbandLightningFastWiFiBoost.primary:
        return (
          <>
            <p className="py-4 md:sky-h5 sky-h5 text-gray-darker">
              Unlimited lightning fast fibre and the latest WiFi 6 tech which is way gruntier than 1-5 (who knew that
              was a thing). This means that when your household is streaming, video calling or gaming, it will handle
              the demand on your WiFi more efficiently. That’s less b…b…buffering and more uninterrupted entertainment
              for the whole family throughout your home.
            </p>
            <p className="pt-4 md:sky-h5 sky-h5 text-gray-darker">
              Need your WiFi to go further? Our Boost plans include the use of a Sky WiFi Booster. We suggest getting a
              Boost plan if your home is:
            </p>
            <ul className="pb-4 md:sky-h5 sky-h5 text-gray-darker marker:text-gray list-outside list-disc ml-6">
              <li>Over 200m2</li>
              <li>Has two or more levels</li>
              <li>Has two or more lounge rooms</li>
              <li>Has four or more bedrooms</li>
              <li>Has any internal walls made from concrete or steel</li>
            </ul>
            <p className="py-4 md:sky-h5 sky-h5 text-gray-darker">
              One booster should do the trick, but if you find you need more coverage give our friendly Kiwi crew a
              call.
            </p>
          </>
        );
      case productSkuIds.broadbandLightningFastWiFi.primary:
      //added this case here to pop up correct data in bb fiber pro exlopre page.
      case productSkuIds.broadbandStarterDiscountOneGig.primary:
        return (
          <p className="py-4 md:sky-h5 sky-h5 text-gray-darker">
            Unlimited lightning fast fibre and the latest WiFi 6 tech which is way gruntier than 1-5 (who knew that was
            a thing). This means that when your household is streaming, video calling or gaming, it will handle the
            demand on your WiFi more efficiently. That’s less b…b…buffering and more uninterrupted entertainment for the
            whole family throughout your home.
          </p>
        );

      case productSkuIds.broadbandEssnFibre.primary:
      case productSkuIds.broadbandEssnFibBoost.primary:
        return (
          <p className="py-4 md:sky-h5 sky-h5 text-gray-darker">
            Ideal for emails, online banking, social media, streaming movies, making video calls with ease and browsing
            the web.
          </p>
        );
      default:
        return (
          <p className="py-4 md:sky-h5 sky-h5 text-gray-darker">
            {`If breakneck speed isn’t your priority then check out Sky Broadband’s ${bbName.broadbandWifi100.label} plans for unlimited fibre
            and the latest WiFi 6 tech.`}
          </p>
        );
    }
  };

  if (!isShown) return null;

  return (
    <AnimatedModal
      isShown={isShown}
      toggleModal={hide}
      withPadding={false}
      fullScreen
      topbarContent={
        <ExploreBanner
          toggleProduct={toggleProduct}
          kkService={kkService}
          product={plan.product!}
          price={price}
          hide={() => {
            window.location.hash = '';
          }}
          removable={true}
          selectedTuiAddress={selectedTuiAddress}
        />
      }>
      <div className="flex flex-col container pt-14 mx-auto">
        <div className="px-12 md:px-0">
          <p className="md:sky-h3 sky-h3">{plan.header.title}</p>
          {caption()}
          <div className="flex flex-wrap my-4 bg-navy text-white">
            <div className="w-full md:w-1/3 flex flex-col items-center p-4 ">
              <div className="w-10 h-10 md:w-12 md:h-12">
                <img src="/images/arrow.svg" alt="arrow" />
              </div>
              <p className="xl:hidden text-bborange sky-h4 font-bold md:sky-h3" style={{ fontSize: fluidFontSize }}>
                {content.products[contentIndex].download.title}
              </p>
              <p className="hidden xl:flex text-bborange sky-h4 font-bold md:sky-h3" style={{ fontSize: '30px' }}>
                {content.products[contentIndex].download.title}
              </p>
              <p className="xl:hidden sky-h4 font-bold md:sky-h3 text-center" style={{ fontSize: fluidFontSize }}>
                {content.products[contentIndex].download.desc}
              </p>
              <p className="hidden xl:flex sky-h4 font-bold md:sky-h3 text-center" style={{ fontSize: '30px' }}>
                {content.products[contentIndex].download.desc}
              </p>
              {/* <p className="sky-h6 pt-4 md:pt-8">
                up to <strong>{content.products[contentIndex].download.speed}</strong>
              </p> */}
            </div>
            <div
              className="w-full md:w-1/3 bg-cover bg-center min-h-200"
              style={{
                backgroundImage: `url(${plan.exploreImg})`,
              }}></div>
            <div className="w-full md:w-1/3 flex flex-col items-center p-4">
              <div className="w-10 h-10 md:w-12 md:h-12  transform rotate-180">
                <img src="/images/arrow.svg" alt="arrow" />
              </div>
              <p className="xl:hidden text-bborange sky-h4 font-bold md:sky-h3" style={{ fontSize: fluidFontSize }}>
                {content.products[contentIndex].upload.title}
              </p>
              <p className="hidden xl:flex text-bborange sky-h4 font-bold md:sky-h3" style={{ fontSize: '30px' }}>
                {content.products[contentIndex].upload.title}
              </p>
              <p className="xl:hidden sky-h4 font-bold md:sky-h3 text-center" style={{ fontSize: fluidFontSize }}>
                {content.products[contentIndex].upload.desc}
              </p>
              <p className="hidden xl:flex sky-h4 font-bold md:sky-h3 text-center" style={{ fontSize: '30px' }}>
                {content.products[contentIndex].upload.desc}
              </p>
              {/* <p className="sky-h6 pt-4 md:pt-8">
                up to <strong>{content.products[contentIndex].upload.speed}</strong>
              </p> */}
            </div>
          </div>

          <p className="text-xs mt-5">
          Fibre Pro and Fibre Everyday speeds shown are based on our network provider's average peak time speeds shown in the Commerce Commission MBNZ Report 22, December 2024. Your speeds may vary especially over WiFi. Factors that can affect your speed include the time of day, where you live, the location and quality of your router and devices and the number of devices being used.
            <a
              className="text-blue-light underline"
              href="https://help.sky.co.nz/s/article/How-fast-is-it-really"
              target="_blank"
              rel="noopener noreferrer">
              &nbsp;Learn more.
            </a>
          </p>

          <BBMarketingInfo />
          <LatestTech configService={configService} />

          <div className="m-10"></div>
        </div>
      </div>
    </AnimatedModal>
  );
};

export { BBExploreModal };
